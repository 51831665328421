import React from "react"
import Layout from "../components/shared/Layout"



const NotFoundPage = () => (
  <Layout>
    <p>No found</p>
  </Layout>
)

export default NotFoundPage
